import React, { FC } from "react";
import { useAuth } from "./AuthContext";
import { Button, Loading } from "antd-mobile";
import { VBox } from "../box/Box";
import google from "./google.svg";

export const Auth: FC<{ authorized: FC }> = (props) => {
    const { user, login, loading } = useAuth();

    const Main = props.authorized;

    return user ? (
        <Main />
    ) : (
        <VBox style={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
            {loading ? (
                <Loading color="primary" />
            ) : (
                <Button color="primary" fill="outline" size="large" onClick={login}>
                    <img src={google} alt="" /> Login with Google
                </Button>
            )}
        </VBox>
    );
};
