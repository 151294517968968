import { createContext, FC, useContext, useEffect, useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithRedirect, User } from "firebase/auth";

function useValue() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const unregisterAuthObserver = getAuth().onAuthStateChanged((user) => {
            setLoading(false);
            setUser(user);
        });
        return () => unregisterAuthObserver();
    }, []);

    const login = () => {
        const provider = new GoogleAuthProvider();
        setLoading(true);
        signInWithRedirect(getAuth(), provider).catch((error) => {
            setLoading(false);
        });
    };

    const logout = () => {
        getAuth().signOut();
    };

    return {
        user,
        loading,
        login,
        logout,
    };
}

type ContextType = ReturnType<typeof useValue>;

const AuthContext = createContext<ContextType>({
    user: null,
    loading: true,
    login: () => {},
    logout: () => {},
});

export const AuthContextProvider: FC = ({ children }) => {
    const auth = useValue();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
