import { FC } from "react";

export const HBox: FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, style, ...props }) => {
    return (
        <div {...props} style={{ ...style, display: "flex", flexDirection: "row" }}>
            {children}
        </div>
    );
};
export const VBox: FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, style, ...props }) => {
    return (
        <div {...props} style={{ ...style, display: "flex", flexDirection: "column" }}>
            {children}
        </div>
    );
};
