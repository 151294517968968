import { FC } from "react";
import { Image } from "antd-mobile";
import { useAuth } from "../auth/AuthContext";
import { HBox } from "../box/Box";
import { LogOut } from "react-feather";
import { Screen } from "../screen/Screen";

export const Settings: FC = () => {
    const { user, logout } = useAuth();

    return (
        <Screen title="Settings">
            <HBox style={{ alignItems: "center" }}>
                {user?.photoURL && (
                    <Image
                        src={user?.photoURL}
                        fit="cover"
                        width={40}
                        height={40}
                        style={{ borderRadius: "50%", marginRight: "0.5rem" }}
                    />
                )}
                <div style={{ flex: 1 }}>
                    <div style={{ fontSize: "1.1rem" }}>{user?.displayName}</div>
                    <div>{user?.email}</div>
                </div>
                <LogOut onClick={logout} color="var(--adm-color-danger)" />
            </HBox>
        </Screen>
    );
};
