import { FC, useState } from "react";
import { Button, TabBar } from "antd-mobile";
import { Workouts } from "../workouts/Workouts";
import { Stats } from "../stats/Stats";
import { Settings } from "../settings/Settings";
import { Activity, BarChart, Settings as SettingsIcon } from "react-feather";
import { VBox } from "../box/Box";

export const Main: FC = () => {
    const [workout, setWorkout] = useState<string>();
    const [activeTab, setActiveTab] = useState<string>("workouts");

    return workout ? (
        <div>
            Working out {workout}
            <Button block onClick={() => setWorkout(undefined)}>
                Stop
            </Button>
        </div>
    ) : (
        <VBox style={{ height: "100vh", justifyContent: "space-between" }}>
            {activeTab === "workouts" && <Workouts setWorkout={setWorkout} />}
            {activeTab === "stats" && <Stats />}
            {activeTab === "settings" && <Settings />}
            <TabBar activeKey={activeTab} onChange={setActiveTab}>
                <TabBar.Item key="workouts" title="Workouts" icon={<Activity />} />
                <TabBar.Item key="stats" title="Stats" icon={<BarChart />} />
                <TabBar.Item key="settings" title="Settings" icon={<SettingsIcon />} />
            </TabBar>
        </VBox>
    );
};
