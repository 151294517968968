import { FC } from "react";
import { Screen } from "../screen/Screen";
import { List } from "antd-mobile";
import { Play } from "react-feather";

interface Props {
    setWorkout: (value: string) => void;
}
export const Workouts: FC<Props> = (props) => {
    const workouts = [
        { id: "1", title: "Push", lastExecution: "2021-10-18" },
        { id: "2", title: "Pull", lastExecution: "2021-10-19" },
        { id: "3", title: "Legs", lastExecution: "2021-10-20" },
    ];
    return (
        <Screen title="Workouts">
            <List>
                {workouts.map((value) => (
                    <List.Item
                        onClick={() => props.setWorkout(value.title)}
                        description={`Last ${value.lastExecution}`}
                        arrow={<Play color="var(--adm-color-success)" />}
                    >
                        {value.title}
                    </List.Item>
                ))}
            </List>
        </Screen>
    );
};
