import { FC } from "react";
import { VBox } from "../box/Box";

interface Props {
    title: string;
}

export const Screen: FC<Props> = (props) => {
    return (
        <VBox style={{ padding: "1rem 0.5rem", height: "calc(100vh - 50px)" }}>
            <div style={{ fontWeight: "bolder", fontSize: "2rem", marginBottom: "0.5rem" }}>{props.title}</div>
            <div style={{ overflow: "scroll" }}>{props.children}</div>
        </VBox>
    );
};
