import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { initializeApp } from "firebase/app";
import { Auth } from "./components/auth/Auth";
import { AuthContextProvider } from "./components/auth/AuthContext";
import { Main } from "./components/main/Main";
import enUS from "antd-mobile/es/locales/en-US";
import { ConfigProvider } from "antd-mobile";

const firebaseConfig = {
    apiKey: "AIzaSyCzodeZudRnQkonLxDI-xv8EphmHTcQ8P8",
    authDomain: "training-routine.firebaseapp.com",
    projectId: "training-routine",
    storageBucket: "training-routine.appspot.com",
    messagingSenderId: "393462635917",
    appId: "1:393462635917:web:d89b1b61c72750dac131c4",
};

initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={enUS}>
            <AuthContextProvider>
                <Auth authorized={Main} />
            </AuthContextProvider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
